<template>
  <modal
    name="mEdit"
    height="auto"
    width="900"
    :scrollable="true"
    @before-open="loadClientes()"
    :clickToClose="false"
  >
    <CloseModal :close="close"/>
    <div class="modalbox">
      <div class="modalbox-content">
        <CTabs variant="tabs" class="nav-tabs-boxed">
          <CTab title="Dados do Usuário">
            <div class="xrow">
              <div class="col-2-3">
                <div class="form-group">
                  <label>Nome</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.nome"
                  />
                  <div class="message">
                    {{ validation.firstError("item.nome") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Ativo</label>
                  <select class="form-control" v-model="item.ativo">
                    <option value>-</option>
                    <option value="1">Sim</option>
                    <option value="0">Não</option>
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.ativo") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label>CPF ou CNPJ (opcional)</label>
                  <TheMask
                    :mask="['###.###.###-##', '##.###.###/####-##']"
                    class="form-control"
                    v-model="item.cpf"
                  />
                  <div class="message">
                    {{ validation.firstError("item.cpf") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>E-mail</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.email"
                  />
                  <div class="message">
                    {{ validation.firstError("item.email") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Telefone</label>
                  <TheMask
                    mask="(##) #########"
                    class="form-control"
                    autocomplete="off"
                    v-model="item.tel"
                  />
                  <div class="message">
                    {{ validation.firstError("item.tel") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label>Data Nascimento (opcional)</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.dtNasc"
                    type="date"
                  />
                  <div class="message">
                    {{ validation.firstError("item.dtNasc") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Senha</label>
                  <input
                    class="form-control"
                    autocomplete="off"
                    v-model="item.senha"
                    type="text"
                  />
                  <div class="message">
                    {{ validation.firstError("item.senha") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="item.visualizaValidade || exibeValidaCarga">
                <div class="form-group">
                  <label>Validade da Carga (Dias)</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.diasValidadeCarga"
                    type="number"
                  />
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-3">
                <div class="form-group">
                  <label>Tipo</label>
                  <select
                    class="form-control"
                    v-model="item.tipoId"
                    v-on:change="cleanUnusedFields"
                  >
                    <option value>-</option>
                    <option
                      v-for="i in tipos"
                      v-bind:value="i.id"
                      v-bind:key="i.id"
                    >{{ i.desc }}
                    </option
                    >
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.tipoId") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3">
                <div class="form-group">
                  <label>Perfil</label>
                  <select class="form-control" v-model="item.perfil">
                    <option value>-</option>
                    <option
                      v-for="i in perfisPossiveis"
                      v-bind:value="i.id"
                      v-bind:key="i.id"
                    >{{ i.descricao }}
                    </option
                    >
                  </select>
                  <div class="message">
                    {{ validation.firstError("item.perfil") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('apiToken')">
                <div class="form-group">
                  <label>
                    Api Token
                    <a
                      class="token-generator"
                      href="#"
                      v-on:click="generateToken()"
                    >
                      Gerar token
                    </a>
                  </label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.apiToken"
                    type="text"
                  />
                  <div class="message">
                    {{ validation.firstError("item.apiToken") }}
                  </div>
                </div>
              </div>
              <div class="col-1-1" v-show="validaTokenFirebase()">
                  <label>Token Firebase:</label>
                  <input
                  class="form-control w-100"
                  autocomplete="chrome-off"
                  v-model="item.firebaseToken"
                  readonly
                  type="text"
                />
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-3"
                v-show="inputShouldBeVisible('orcamentosParados')"
              >
                <div class="form-group">
                  <label>Número de Orçamentos Sem Enviar</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.orcamentosParados"
                    type="number"
                  />
                  <div class="message">
                    {{ validation.firstError("item.orcamentosParados") }}
                  </div>
                </div>
              </div>
              <div
                class="col-1-3"
                v-show="inputShouldBeVisible('percMaxNegociacao')"
              >
                <div class="form-group">
                  <label>Perc. máx. aprovar negociação</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.percMaxNegociacao"
                    type="number"
                  />
                  <div class="message">
                    {{ validation.firstError("item.percMaxNegociacao") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('visualizaSetor')">
                <div class="form-group">
                  <label for="">Setor Atendimento</label>
                  <select class="form-control" v-model="item.setor_atendimento_id">
                    <option v-for="setor in setores" v-bind:key="setor.id" :value="setor.id">
                      {{ setor.desc }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('visualizaExpediente')">
                <div class="form-group">
                  <label for="">Expediente p/ Login</label>
                  <select class="form-control" v-model="item.expedienteId">
                    <option :value="null">-</option>
                    <option v-for="expediente in expedientes" v-bind:key="expediente.id" :value="expediente.id">
                      {{ expediente.desc }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-3" v-show="inputShouldBeVisible('matErp')">
                <div class="form-group">
                  <label>Matrícula ERP</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.matErp"
                    type="text"
                  />
                  <div class="message">
                    {{ validation.firstError("item.matErp") }}
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('ramal')">
                <div class="form-group">
                  <label>Ramal</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.ramal"
                    type="text"
                  />
                  <div class="message">
                    {{ validation.firstError("item.ramal") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1" v-show="inputShouldBeVisible('codOp')">
                <div class="xrow">
                  <div class="form-group">
                    <div class="col-1-4">
                      <label>Cód. Operador</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codOp"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codOp") }}
                      </div>
                    </div>
                    <div class="col-1-4">
                      <label>Cód. Operador 2</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codOp2"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codOp2") }}
                      </div>
                    </div>
                    <template v-if="item.preferencias">
                    <div class="col-1-4" v-show="inputShouldBeVisible('permiteVendaForaCarteira')">
                      <label>Permite venda fora da carteira</label>
                      <select class="form-control" v-model="item.preferencias.permite_venda_fora_carteira">
                        <option :value="null">Usar do parâmetro geral</option>
                        <option :value="1">Sim</option>
                        <option :value="0">Não</option>
                      </select>
                    </div>
                    </template>
                  </div>
                </div>
              </div>
              <div class="col-1-1" v-show="inputShouldBeVisible('codRca')">
                <div class="xrow">
                  <div class="form-group">
                    <div class="col-1-4">
                      <label>Cód. RCA</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codRca"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codRca") }}
                      </div>
                    </div>
                    <div class="col-1-4">
                      <label>Cód. RCA 2</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codRca2"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codRca2") }}
                      </div>
                    </div>
                    <div class="col-1-4" v-show="inputShouldBeVisible('usaContaCorrente')">
                      <label>Usa Conta Corrente</label>
                      <select class="form-control" v-model="item.usaContaCorrente">
                        <option :value="null">Usar do parâmetro geral</option>
                        <option :value="1">Sim</option>
                        <option :value="0">Não</option>
                      </select>
                    </div>
                    <div class="col-1-4" v-show="inputShouldBeVisible('permiteNegociar')">
                      <label>Permite Negociação</label>
                      <select class="form-control" v-model="item.permiteNegociar">
                        <option :value="null">Usar do parâmetro geral</option>
                        <option :value="1">Sim</option>
                        <option :value="0">Não</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('codSuperv')">
                <div class="xrow">
                  <div class="form-group">
                    <div class="col-1-2">
                      <label>Cód. Superv.</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codSuperv"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codSuperv") }}
                      </div>
                    </div>
                    <div class="col-1-2">
                      <label>Cód. Superv. 2</label>
                      <input
                        class="form-control"
                        autocomplete="chrome-off"
                        v-model="item.codSuperv2"
                        type="text"
                      />
                      <div class="message">
                        {{ validation.firstError("item.codSuperv2") }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1-3" v-show="inputShouldBeVisible('codGer')">
                <div class="form-group">
                  <label>Cód. Gerente</label>
                  <input
                    class="form-control"
                    autocomplete="chrome-off"
                    v-model="item.codGer"
                    type="text"
                  />
                  <div class="message">
                    {{ validation.firstError("item.codGer") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div class="col-1-1" v-show="inputShouldBeVisible('assumePed')">
                <div class="form-group">
                  <label>
                    <input type="checkbox" v-model="item.assumePed"/>
                    Assumir pedidos (os pedidos enviados pelo colaborador receberão o cód. do vendedor como emitente do pedido)
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-1"
                v-show="inputShouldBeVisible('registraAtendimentoCliente')"
              >
                <div class="form-group" v-if="item.preferencias">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.preferencias.registra_atendimento_cliente"
                    />
                    Solicita registro de atendimento ao fechar tela do cliente
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-1"
                v-show="inputShouldBeVisible('registraAtendimentoOrcamento')"
              >
                <div class="form-group" v-if="item.preferencias">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.preferencias.registra_atendimento_orcamento"
                    />
                    Solicita registro de atendimento ao fechar tela de orçamento
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-1"
                v-show="inputShouldBeVisible('reagendaOrcamento')"
              >
                <div class="form-group" v-if="item.preferencias">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.preferencias.reagenda_orcamento"
                    />
                    Solicita o reagendamento do atendimento ao fechar tela de
                    orçamento
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-1"
                v-show="inputShouldBeVisible('importaBackups')"
              >
                <div class="form-group">
                  <label>
                    <input type="checkbox" v-model="item.importaBackups"/>
                    Permite que o usuário importe backups de seus orçamentos no
                    App VersoVendas
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow">
              <div
                class="col-1-1"
                v-show="inputShouldBeVisible('visualizaSomenteCarteira')"
              >
                <div class="form-group">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.visualizaSomenteCarteira"
                    />
                    <span v-if='getTipoColaborador() === "OPERADOR"'> Visualiza somente clientes da carteira</span>
                    <span v-else> Visualiza apenas rcas e clientes da carteira</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="xrow" v-if="isOperadorOrRepresentante && inputShouldBeVisible('usaRestricaoSegmentoVendaSupervisor')">
              <div class="col-1-1">
                <div class="form-group">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.usaRestricaoSegmentoVendaSupervisor"
                    />
                    Utiliza restrições de segmento de venda do supervisor
                  </label>
                </div>
              </div>
            </div>
            <div 
              class="xrow" 
              v-show="inputShouldBeVisible('utilizaSaldoContaCorrenteOutroVendedor')"
            >
              <div class="col-1-1">
                <div class="form-group">
                  <label>
                    <input
                      type="checkbox"
                      v-model="item.utilizaSaldoContaCorrenteOutroVendedor"
                    />
                    Permite utilizar saldo da conta corrente de outros vendedores (regra utilizada para venda com cliente fora da carteira)
                  </label>
                </div>
              </div>
            </div>
          </CTab>
          <CTab title="Clientes ( B2B )" v-if="item.id">
            <div class="xrow">
              <div class="col-1-1">
                <div class="col-1-2">
                  <span class="clientes filters__search">
                    <input
                      type="text"
                      v-model="filter"
                      v-on:input="debounceLoadClientes()"
                      placeholder="Filtrar por id, cnpj ou razão social"
                    />
                    <img
                      src="data:image/svg+xml;utf8;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMC4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDU2Ljk2NiA1Ni45NjYiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDU2Ljk2NiA1Ni45NjY7IiB4bWw6c3BhY2U9InByZXNlcnZlIiB3aWR0aD0iMTZweCIgaGVpZ2h0PSIxNnB4Ij4KPHBhdGggZD0iTTU1LjE0Niw1MS44ODdMNDEuNTg4LDM3Ljc4NmMzLjQ4Ni00LjE0NCw1LjM5Ni05LjM1OCw1LjM5Ni0xNC43ODZjMC0xMi42ODItMTAuMzE4LTIzLTIzLTIzcy0yMywxMC4zMTgtMjMsMjMgIHMxMC4zMTgsMjMsMjMsMjNjNC43NjEsMCw5LjI5OC0xLjQzNiwxMy4xNzctNC4xNjJsMTMuNjYxLDE0LjIwOGMwLjU3MSwwLjU5MywxLjMzOSwwLjkyLDIuMTYyLDAuOTIgIGMwLjc3OSwwLDEuNTE4LTAuMjk3LDIuMDc5LTAuODM3QzU2LjI1NSw1NC45ODIsNTYuMjkzLDUzLjA4LDU1LjE0Niw1MS44ODd6IE0yMy45ODQsNmM5LjM3NCwwLDE3LDcuNjI2LDE3LDE3cy03LjYyNiwxNy0xNywxNyAgcy0xNy03LjYyNi0xNy0xN1MxNC42MSw2LDIzLjk4NCw2eiIgZmlsbD0iIzAwNDE2ZiIvPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8Zz4KPC9nPgo8L3N2Zz4K"
                    />
                  </span>
                </div>
              </div>
              <div class="col-1-2">
                <div class="scrollable lista-clientes" style="height: 600px;">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th colspan="3">Clientes</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="cliente in clientes" v-bind:key="cliente.id">
                        <td colspan="3">
                          <div class="line">
                            <div>
                                <span class="pre-text"
                                >ID: {{ cliente.id }}&nbsp;-&nbsp; CNPJ:
                                  {{ cliente.cnpj }}</span
                                >
                              <div class="main-text">{{ cliente.razSoc }}</div>
                            </div>
                            <div class="actions">
                              <button
                                v-on:click="addCliente(item.id, cliente.id)"
                                class="button button-success"
                                :disabled="clienteJaAdicionado(cliente.id)"
                              >
                                <svg
                                  x="0px"
                                  y="0px"
                                  viewBox="0 0 42 42"
                                  style="width: 12px; height: 12px; fill: rgb(255, 255, 255);"
                                >
                                  <path
                                    d="M37.059,16H26V4.941C26,2.224,23.718,0,21,0s-5,2.224-5,4.941V16H4.941C2.224,16,0,18.282,0,21s2.224,5,4.941,5H16v11.059 C16,39.776,18.282,42,21,42s5-2.224,5-4.941V26h11.059C39.776,26,42,23.718,42,21S39.776,16,37.059,16z"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-1-2">
                <div class="scrollable lista-clientes" style="height: 600px">
                  <div class="table-responsive">
                    <table class="table table-hover">
                      <thead>
                      <tr>
                        <th colspan="3">Clientes Vinculados</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr
                        v-for="cliente in item.clientes"
                        v-bind:key="cliente.id"
                      >
                        <td colspan="3">
                          <div class="line">
                            <div>
                              <span class="pre-text">ID: {{ cliente.id }}</span>
                              <h6 class="main-text">{{ cliente.razSoc }}</h6>
                            </div>
                            <span class="actions">
                                <button
                                  type="button"
                                  name="button"
                                  v-on:click="remCliente(item.id, cliente.id)"
                                  class="button button-error"
                                >
                                  <fa-icon icon="trash-alt"/>
                                </button>
                              </span>
                          </div>
                          <div class="details">
                            <DetalhesCliente :cliente="cliente"/>
                          </div>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </CTab>
          <CTab v-if="usaRestricaoSegmento" title="Segmentos Bloqueados">
            <SegmentoVendaDimensoes
              :colaborador="item"
              :items="dimensoesSegmentoVenda"
              :upInsertDimensao="upInsertDimensao"
              :dimensoesBloqueadasSegmentoVenda="dimensoesBloqueadasSegmentoVenda"
              :insertAllDimensao="insertAllDimensao"
              :deleteAllDimensao="deleteAllDimensao"
              :key="componentSegmentoVendaDimensoes"
              :parametros="parametros"
              />
          </CTab>
          <CTab v-if="usaRestricaoSegmento" title="Clientes Exceção">
            <SegmentoVendaClientes
              :colaborador="item"
              :items="clientesSegmentoVenda"
              :upInsertClienteExcecao="upInsertClienteExcecao"
              :insertAllClientes="insertAllClientes"
              :deleteAllClientes="deleteAllClientes"
              :key="componentSegmentoVendaClientes"
              :parametros="parametros"
              />
          </CTab>
          <CTab v-if="item.id" title="Licenciamento">
            <Licenciamento
              :item="item"
              :licencas="licencasDisponiveis"
              />
          </CTab>
        </CTabs>
      </div>
    </div>
    <div class="modal-footer">
      <div v-if="+item.aguardando === 0">
        <CButtonToolbar>
            <CDropdown
              color="info"
              class="mx-3"
              placement="bottom-end"
              button-content="Menu"
              toggler-text="Ações"
              v-if="item.id"
            >              
              <CDropdownItem @click="desvincularDispositivos(item.id)">Desvincular Dispositivos</CDropdownItem>
            </CDropdown>
            <button
              :class="{
                'button button-success': true,
                'button-disabled': validation.hasError(),
              }"
              v-on:click="handleSubmit(false)"
            >
              {{ !!item.id ? "Alterar" : "Cadastrar" }}
            </button>
            <button class="button" v-on:click="close">Fechar</button>
          </CButtonToolbar>
        
      </div>
      <div v-else>
        <button
          :class="{
            'button button-success': true,
            'button-disabled': validation.hasError(),
          }"
          v-on:click="handleSubmit(true)"
        >
          Liberar Colaborador
        </button>
        <button
          :class="{ 'button button-error': true }"
          v-on:click="remove(item)"
        >
          Remover do Sistema
        </button>
        <button class="button" v-on:click="close">Fechar</button>
      </div>
    </div>
  </modal>
</template>
<script>
import {TheMask} from "vue-the-mask";
import {Validator} from "simple-vue-validator";
import {isCpf, isNumeric} from "../../helpers/utils";
import {get, put, post} from "../../helpers/apiRequest";
import _filter from "lodash/filter";
import _some from "lodash/some";
import _debounce from "lodash/debounce";
import _find from "lodash/find";
import {uuid} from "vue-uuid";
import DetalhesCliente from "../Clientes/DetalhesCliente";
import CloseModal from "../../components/CloseModal";
import SegmentoVendaDimensoes from "./components/Dimensoes";
import SegmentoVendaClientes from "./components/Clientes";
import Licenciamento from "./components/Licenciamento";
import {isEmpty} from "lodash";

export default {
  name: "mEdit",
  components: {
    DetalhesCliente,
    TheMask,
    CloseModal,
    SegmentoVendaDimensoes,
    SegmentoVendaClientes,
    Licenciamento,
  },
  props: [
    "update",
    "store",
    "remove",
    "close",
    "item",
    "perfis",
    "setores",
    "expedientes",
    "tipos",
    "addCliente",
    "remCliente",
    "parametros",
  ],
  validators: {
    "item.nome": (value) => Validator.value(value).required(),
    "item.ativo": (value) => Validator.value(value).required(),
    "item.cpf": {
      validator(value) {
        return Validator.custom(() => {
          if (!value) return false;
        });
      },
    },
    "item.email": (value) =>
      Validator.value(value)
        .required()
        .email(),
    "item.perfil": (value) => Validator.value(value).required(),
    "item.tipoId": (value) => Validator.value(value).required(),
    "item.matErp": {
      validator(value) {
        return Validator.custom(() =>
          this.extraFieldValidator("matErp", value)
        );
      },
    },
    "item.codOp": {
      validator(value) {
        return Validator.custom(() => this.extraFieldValidator("codOp", value));
      },
    },
    "item.codRca": {
      validator(value) {
        return Validator.custom(() =>
          this.extraFieldValidator("codRca", value)
        );
      },
    },
    "item.ramal": {
      validator(value) {
        return Validator.custom(() => this.extraFieldValidator("ramal", value));
      },
    },
    "item.apiToken": {
      validator(value) {
        return Validator.custom(() =>
          this.extraFieldValidator("apiToken", value)
        );
      },
    },
  },
  data() {
    return {
      filter: "",
      clientes: [],
      clientesSegmentoVenda: [],
      dimensoesSegmentoVenda: [],
      dimensoesBloqueadasSegmentoVenda: [],
      licencasDisponiveis: [],
      resultLimit: 100,
      activeTab: "dados",
      componentSegmentoVendaClientes: 0,
      componentSegmentoVendaDimensoes: 0,
    };
  },
  computed: {
    perfisPossiveis() {
      return _filter(this.perfis, (p) => {
        return _some(p.perfisTipos, (tipo) => tipo === this.item.tipoId);
      });
    },
    usaRestricaoSegmento() {
      return this.item.id &&
        (this.tipos[this.item.tipoId].chave === "OPERADOR"
          || this.tipos[this.item.tipoId].chave === "SUPERVISOR"
          || this.tipos[this.item.tipoId].chave === "REPRESENTANTE"
          || this.tipos[this.item.tipoId].chave === "GERENTE")
    },
    isOperadorOrRepresentante() {
      return this.item.id && 
        (this.tipos[this.item.tipoId].chave === "OPERADOR"
        || this.tipos[this.item.tipoId].chave === "REPRESENTANTE");
    },
    exibeValidaCarga(){

      if (typeof this.item.tipo !== 'undefined') {
          const tipoUser = this.tipos[this.item.tipoId].chave;
          switch (tipoUser) {
            case 'SUPERVISOR':
              return true;
              break;
            case 'REPRESENTANTE':
              return true;
              break;
            case 'ADMIN':
              return true;
              break;
            case 'SUPERVISOR_CALLCENTER':
              return true;
              break;
            case 'SUPERVISOR_FV_CALLCENTER':
              return true;
              break;
            default:
              return false;
          }
        }else{
          return false;
        }
    }
  },
  watch: {
    "item.id"(colaboradorId) {
      if (colaboradorId && this.usaRestricaoSegmento) {
        this.cargaSegmentoVenda(colaboradorId, false)
      }
      if (colaboradorId) {
        this.loadLicencasDisponiveis(colaboradorId)
      }     
    },
    "item.tipoId"(tipoId) {
      if (!this.isOperadorOrRepresentante) {
        this.item.usaRestricaoSegmentoVendaSupervisor = 0;
      }
    }
  },
  methods: {
    validaTokenFirebase( ){
      return !isEmpty(this.item.firebaseToken);
    },
    clienteJaAdicionado(idCli) {
      return _find(this.item.clientes, {id: idCli});
    },
    getTipoColaborador(){
      return this.tipos[this.item?.tipoId]?.chave;
    },
    cleanUnusedFields(e) {
      this.item.matErp = null;
      this.item.codOp = null;
      this.item.codRca = null;
      this.item.usaContaCorrente = null;
      this.item.orcamentosParados = null;
      this.item.ramal = null;
      this.item.percMaxNegociacao = null;
      this.item.assumePed = null;
      this.item.apiToken = null;
      this.item.visualizaValidade = this.usaValidadeCarga();
    },
    extraFieldValidator(field, value) {
      if (field === "ramal" || field === "codOp" || field === "codRca") {
        return;
      }

      if (
        field === "matErp" &&
        this.tipos[this.item.tipoId] &&
        this.tipos[this.item.tipoId].chave === "FUNCIONARIO"
      ) {
        return;
      }

      if (this.inputShouldBeVisible(field) && (!value || value.length <= 0)) {
        return "Required";
      }
    },
    loadClientes(page = 1, take = 15) {
      this.clientesSegmentoVenda = []
      this.dimensoesSegmentoVenda = []
      this.dimensoesBloqueadasSegmentoVenda = []
      get(`/admin/clientes?page=${page}&take=${take}&q=${this.filter}`)
        .then((json) => {
          this.clientes = json.itens;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    cargaSegmentoVenda(colaboradorId, showAlert = true) {
      this.reloadDimensoes();
      this.reloadClientes();

      if (showAlert) {
        this.$swal({
          title: 'Carga realizada com sucesso',
          showCancelButton: false,
          cancelButtonText: 'Fechar',
          showConfirmButton: true,
          icon: 'success',
        });
      }
    },
    loadClientesSegmentoVenda(colaboradorId) {
      get(`/admin/segmento-venda/clientes/${colaboradorId}`)
        .then((json) => {
          this.clientesSegmentoVenda = json;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    upInsertClienteExcecao(colaboradorId, clienteId, status) {
      post(`/admin/segmento-venda/atualiza-cliente/`, {
        'colaboradorId': colaboradorId,
        'clienteId': clienteId,
        'status': status
      })
        .then((json) => {
          // this.$swal({
          //   title: 'Salvo com sucesso',
          //   text: json.message,
          //   showCancelButton: false,
          //   cancelButtonText: 'Fechar',
          //   showConfirmButton: true,
          //   icon: 'success',
          // });
        })
        .catch((error) => {
          // this.$swal({
          //   title: 'Falha ao Salvar',
          //   text: error.message,
          //   showCancelButton: true,
          //   cancelButtonText: 'Fechar',
          //   showConfirmButton: false,
          //   icon: 'error',
          // });
        });
        this.reloadClientes();
    },
    loadDimensoesSegmentoVenda(colaboradorId) {
      get(`/admin/segmento-venda/dimensoes/${colaboradorId}`)
        .then((json) => {
          this.dimensoesSegmentoVenda = json;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    upInsertDimensao(colaboradorId, dimensaoId, status, colunaDimensaoCategoria) {
      post(`/admin/segmento-venda/atualiza-dimensao/`, {
        'colaboradorId': colaboradorId,
        'id': dimensaoId,
        'status': status,
        'coluna': colunaDimensaoCategoria
      })
        .then((json) => {          
          // this.$swal({
          //   title: 'Salvo com sucesso',
          //   text: json.message,
          //   showCancelButton: false,
          //   cancelButtonText: 'Fechar',
          //   showConfirmButton: true,
          //   icon: 'success',
          // });
        })
        .catch((error) => {
          // this.$swal({
          //   title: 'Falha ao Salvar',
          //   text: error.message,
          //   showCancelButton: true,
          //   cancelButtonText: 'Fechar',
          //   showConfirmButton: false,
          //   icon: 'error',
          // });
        });
        this.reloadDimensoes();
    },
    loadDimensoesBloqueadasSegmentoVenda(colaboradorId) {
      get(`/admin/segmento-venda/dimensoes-bloqueadas/${colaboradorId}`)
        .then((json) => {
          this.dimensoesBloqueadasSegmentoVenda = json;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    reloadDimensoes(remountComponent = null) {
      this.loadDimensoesSegmentoVenda(this.item.id)
      this.loadDimensoesBloqueadasSegmentoVenda(this.item.id)
      if(remountComponent){
        this.componentSegmentoVendaDimensoes += 1;
      }
    },
    reloadClientes(remountComponent = null) {
      this.loadClientesSegmentoVenda(this.item.id)
      if(remountComponent){
        this.componentSegmentoVendaClientes += 1;
      }
    },
    insertAllDimensao(dimensoesInsert) {
      put(`/admin/segmento-venda/check-all-dimensao/`, dimensoesInsert)
        .then((json) => {
          this.reloadDimensoes(true);
           this.$swal({
             title: 'Salvo com sucesso',
             text: json.message,
             showCancelButton: false,
             cancelButtonText: 'Fechar',
             showConfirmButton: true,
             icon: 'success',
           });
        })
        .catch((error) => {
           this.$swal({
             title: 'Falha ao Salvar',
             text: error.message,
             showCancelButton: true,
             cancelButtonText: 'Fechar',
             showConfirmButton: false,
             icon: 'error',
           });
        });
    },
    deleteAllDimensao(dimensoesDelete) {
      put(`/admin/segmento-venda/uncheck-all-dimensao/`, dimensoesDelete)
        .then((json) => {
          this.reloadDimensoes(true);
           this.$swal({
             title: 'Salvo com sucesso',
             text: json.message,
             showCancelButton: false,
             cancelButtonText: 'Fechar',
             showConfirmButton: true,
             icon: 'success',
           });
        })
        .catch((error) => {
           this.$swal({
             title: 'Falha ao Salvar',
             text: error.message,
             showCancelButton: true,
             cancelButtonText: 'Fechar',
             showConfirmButton: false,
             icon: 'error',
           });
        });
    },
    insertAllClientes(clientesInsert, colaboradorId) {
      put(`/admin/segmento-venda/${colaboradorId}/check-all-clientes/`, clientesInsert)
        .then((json) => {
          this.reloadClientes(true);
           this.$swal({
             title: 'Salvo com sucesso',
             text: json.message,
             showCancelButton: false,
             cancelButtonText: 'Fechar',
             showConfirmButton: true,
             icon: 'success',
           });
        })
        .catch((error) => {
           this.$swal({
             title: 'Falha ao Salvar',
             text: error.message,
             showCancelButton: true,
             cancelButtonText: 'Fechar',
             showConfirmButton: false,
             icon: 'error',
           });
        });
    },
    deleteAllClientes(clientesDelete, colaboradorId) {
      put(`/admin/segmento-venda/${colaboradorId}/uncheck-all-dimensao/`, clientesDelete)
        .then((json) => {
          this.reloadClientes(true);
           this.$swal({
             title: 'Salvo com sucesso',
             text: json.message,
             showCancelButton: false,
             cancelButtonText: 'Fechar',
             showConfirmButton: true,
             icon: 'success',
           });
        })
        .catch((error) => {
           this.$swal({
             title: 'Falha ao Salvar',
             text: error.message,
             showCancelButton: true,
             cancelButtonText: 'Fechar',
             showConfirmButton: false,
             icon: 'error',
           });
        });
    },
    debounceLoadClientes: _debounce(function () {
      this.loadClientes();
    }, 300),
    handleSubmit(liberar) {
      this.$validate().then((success) => {
        if (success) {
          let request;

          if (!!this.item.id) {
            request = this.update(liberar);
          } else {
            request = this.store(this.item);
          }

          request.catch((error) => {
            this.$swal({
              title: "Ops!",
              text: error.message,
              icon: "error",
            });
          });
        }
      });
    },
    changeTab(tab) {
      if (this.activeTab !== tab) {
        this.activeTab = tab;
      }
    },
    inputShouldBeVisible(inputName) {
      return (
        this.tipos[this.item.tipoId] &&
        this.tipos[this.item.tipoId].inputs.indexOf(inputName) >= 0
      );
    },
    generateToken() {
      this.item.apiToken = uuid.v4();
    },
    loadLicencasDisponiveis(colaboradorId) {
      get(`/admin/licencas/${colaboradorId}`)
        .then((json) => {
          this.licencasDisponiveis = json;
          this.loading = false;
        })
        .catch((err) => (this.loading = false));
    },
    usaValidadeCarga(){
        if (typeof this.tipos[this.item.tipoId].chave !== 'undefined') {
          const tipoUser = this.tipos[this.item.tipoId].chave;
          switch (tipoUser) {
            case 'SUPERVISOR':
              return true;
              break;
            case 'REPRESENTANTE':
              return true;
              break;
            case 'ADMIN':
              return true;
              break;
            case 'SUPERVISOR_CALLCENTER':
              return true;
              break;
            case 'SUPERVISOR_FV_CALLCENTER':
              return true;
              break;
            default:
              return false;
          }
        }else{
          return false;
        }
    },
    desvincularDispositivos(colaboradorId) {
      put(`/admin/colaboradores/${colaboradorId}/desvincular-dispositivos`)
        .then((json) => {          
          this.$swal({
            title: 'Salvo com sucesso',
            text: json.message,
            showCancelButton: false,
            cancelButtonText: 'Fechar',
            showConfirmButton: true,
            icon: 'success',
          });          
        })
        .catch((error) => {
          this.$swal({
            title: 'Falha ao Salvar',
            text: error.message,
            showCancelButton: true,
            cancelButtonText: 'Fechar',
            showConfirmButton: false,
            icon: 'error',
          });
        });
    }
  }
};
</script>

<style scoped>
.line {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pre-text {
  font-size: 10px;
}

.main-text {
  font-size: 14px;
  font-weight: bold;
}

.details {
  background-color: #fafafa;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #ddd;
}
</style>
